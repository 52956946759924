<template>
  <div class="categories">
    <div class="categories-wrapper">
      <!-- навигац. цепочка -->
      <div class="nav-chain">
        <div class="chain">
          <div class="breadcrumbs">
            <router-link :to="{ name: 'Groups' }" class="link"
              >Группы</router-link
            >
            <div class="divider">/</div>
            <div class="link disabled">Группа {{ group.title }}</div>
          </div>
          <!-- кнопка вызова модалки создания категории -->
          <v-btn
            color="white"
            class="btn"
            v-show="categories.length > 0"
            @click="openCreateModal"
          >
            <v-img
              alt="login"
              contain
              src="@/assets/plus.svg"
              transition="scale-transition"
              width="37"
            /><span class="btn__text">Добавить</span>
          </v-btn>
        </div>
      </div>
      <!-- пустые карточки для фона -->
      <div class="cards" v-show="categories.length === 0">
        <div class="card-wrapper" v-for="n in 12" :key="n"></div>
      </div>
      <!-- заполненные карточки -->
      <div class="cards" v-if="categories">
        <div
          class="card-wrapper"
          v-for="(category, index) in categories"
          :key="index"
        >
          <category-card
            :name="category.title"
            :id="category.id"
            @edit="openEditModal(category.id, category.title)"
            @delete="
              deletingId = category.id;
              deleteModalShow = true;
            "
          />
        </div>
      </div>
    </div>
    <!-- если нет категорий созданных -->
    <div class="noCards" v-show="categories.length === 0 && !categoryModalShow">
      <div class="text">
        Для добавления первой карточки категории нажмите кнопку “Добавить”
      </div>
      <!-- кнопка вызова модалки создания категории -->
      <v-btn color="white" class="btn" @click="openCreateModal">
        <v-img
          alt="login"
          contain
          src="@/assets/plus.svg"
          transition="scale-transition"
          width="37"
        /><span class="btn__text">Добавить</span>
      </v-btn>
    </div>
    <!-- модалка редактирования или создания категории -->
    <category-modal
      :show="categoryModalShow"
      :text="editedName"
      :type="categoryModalType"
      @closed="categoryModalShow = false"
      @accepted="
        categoryModalType == 'create'
          ? createCategory($event)
          : editCategory(editedId, $event)
      "
    />
    <!-- модалка подтверджения удаления -->
    <delete-modal
      :show="deleteModalShow"
      @closed="deleteModalShow = false"
      @accepted="deleteCategory(deletingId)"
    />
  </div>
</template>

<script>
import axios from "axios";
import categoryModal from "@/components/Categories/categoryModal";
import categoryCard from "@/components/Categories/categoryCard";
import deleteModal from "@/components/deleteModal";
export default {
  components: {
    categoryModal,
    categoryCard,
    deleteModal,
  },
  data() {
    return {
      categoryModalShow: false,
      // create edit
      categoryModalType: "create",
      // текст редактируемой карточки в данный момент
      editedName: "",
      editedId: "",

      deleteModalShow: false,
      deletingId: "",
      categories: [],
      group: {},
    };
  },
  methods: {
    openCreateModal() {
      this.editedName = "";
      this.categoryModalType = "create";
      this.categoryModalShow = true;
    },
    createCategory(data) {
      return new Promise((resolve, reject) => {
        axios
          .post("/categories", {
            title: data,
            group_id: this.$route.params.id,
          })
          .then((resp) => {
            this.categoryModalShow = false;
            this.getCategories();
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            console.log(err);
          });
      });
    },
    getCategories() {
      return new Promise((resolve, reject) => {
        axios
          .get(`/categories?group_id=${this.$route.params.id}`)
          .then((resp) => {
            this.categories = resp.data;
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            console.log(err);
          });
      });
    },
    getGroup() {
      return new Promise((resolve, reject) => {
        axios
          .get(`/groups/${this.$route.params.id}`)
          .then((resp) => {
            this.group = resp.data;
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            console.log(err);
          });
      });
    },
    openEditModal(id, title) {
      this.editedId = id;
      this.editedName = title;
      this.categoryModalType = "edit";
      this.categoryModalShow = true;
    },

    editCategory(id, title) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/categories/${id}`, {
            title: title,
          })
          .then((resp) => {
            this.categoryModalShow = false;
            this.getCategories();
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            console.log(err);
          });
      });
    },
    deleteCategory(id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/categories/${id}`)
          .then((resp) => {
            this.deleteModalShow = false;
            this.getCategories();
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            console.log(err);
          });
      });
    },
  },
  created() {
    this.getGroup();
    this.getCategories();
  },
};
</script>

<style lang="scss" scoped>
.categories {
  position: relative;
  height: 100%;
  &-wrapper {
    padding: 0 140px 0 170px;
    height: 100%;
  }
}
.nav-chain {
  padding-top: 0;
  padding-bottom: 0;
  z-index: 11;
}
.chain {
  padding-right: 28px;
  padding-top: 40px;
  padding-bottom: 58px;
  text-indent: 12px;
  font-size: 14px;
  line-height: 21px;
  color: rgba(54, 66, 165, 0.65);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.breadcrumbs {
  display: flex;
  flex-direction: row;
  .divider {
    color: rgba(0, 0, 0, 0.38);
    font-size: 14px;
    margin-right: 12px;
    margin-left: 12px;
  }
  .link {
    color: rgba(54, 66, 165, 0.65);
    font-size: 14px;
    line-height: 21px;
    text-indent: 12px;
    text-decoration: none;
    cursor: pointer;
    &.disabled {
      cursor: default;
      color: #3642a5;
      font-size: 14px;
      line-height: 21px;
    }
  }
}
.cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  // height: 100%;
  width: 100%;
  box-sizing: border-box;
  max-height: 100%;
}
.card-wrapper {
  box-sizing: border-box;
  width: 353px;
  height: 157px;
  background: #e9e8e8;
  // background: black;
  border-radius: 5px;
  margin-bottom: 38px;
  margin-right: 22px;
}

.noCards {
  z-index: 10;
  position: absolute;
  width: 100%;
  background: rgba(108, 108, 108, 0.15);
  height: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .text {
    max-width: 408px;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #333333;
    margin-bottom: 32px;
  }
  .btn {
    width: 167px;
    height: 47px;
    display: flex;
    flex-direction: row;
    align-items: center;
    &__text {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      text-transform: uppercase;
      color: #3642a5;
      margin-left: 11px;
    }
  }
}
</style>
